<template>
  <div class="cpt-mount cpt-MMC_Gimbal_ZT30N">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.ptz_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">画 中 画</div>
          <div class="input-box">
            <el-select
              @change="handle_change_image_mode"
              v-model="image_mode"
              size="mini"
              placeholder="请选择画中画"
            >
              <el-option label="主高清" :value="0"></el-option>
              <el-option label="主红外" :value="1"></el-option>
              <el-option label="单高清" :value="2"></el-option>
              <el-option label="单红外" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            伪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;彩
          </div>
          <div class="input-box">
            <el-select
              @change="handle_change_thermal_mode"
              v-model="thermal_mode"
              size="mini"
              placeholder="请选择彩伪"
            >
              <el-option label="白热" :value="0"></el-option>
              <el-option label="黑热" :value="1"></el-option>
              <el-option label="彩虹" :value="2"></el-option>
              <el-option label="熔岩" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子变倍</div>
          <div class="input-box">
            <el-select
              @change="handle_change_thermal_zoom"
              v-model="thermal_zoom"
              size="mini"
              placeholder="请选择电子变倍"
            >
              <el-option label="1倍" :value="1"></el-option>
              <el-option label="2倍" :value="2"></el-option>
              <el-option label="4倍" :value="4"></el-option>
              <el-option label="6倍" :value="6"></el-option>
              <el-option label="8倍" :value="7"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div
              class="mr10"
              @mousedown="handle_zoom(-1)"
              @mouseup="stopChange"
            >
              <span style="font-size: 20px; color: #009aff; padding: 0 5px;" class="icon el-icon-remove-outline"></span>
            </div>
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              style="width: 180px"
              @change="stopChange"
              @input="onChangezoom"
            ></el-slider>
            <div
              class="ml10"
              @mouseenter="handle_zoom(1)"
              @mouseleave="stopChange"
            >
              <span style="font-size: 20px; color: #009aff; padding: 0 5px;" class="icon el-icon-circle-plus-outline"></span>
            </div>
          <!-- <div class="input-box">
            <el-button
              @click="handle_zoom(1)"
              size="mini"
              icon="el-icon-plus"
              circle
            ></el-button>
            <el-button
              @click="handle_zoom(0)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button> -->
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">控&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;制</div>
        </div>
        <div class="rocker-box">
          <div class="rocker" ref="rocker"></div>
        </div>
      </div>
    </div>

</template>

<script>
import nipplejs from "nipplejs";
import { MMC_Gimbal_ZT30N } from "../utils";
export default {
  props: {
    whachChange:{
      typeof:Number,
      default:0
    }
  },
  data() {
    return {
      joystick:null,
      control_speed: 3,
      ptz_mode: 0,
      image_mode: 0,
      thermal_mode: 0,
      thermal_zoom: 1,
      value: 25,
      value2: 25,
      radio: 1,

      drd: {
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
      },
    };
  },
  methods: {
    onChangezoom(e) {
      if (this.value2 < this.value) {
        this.handle_zoom(1);
      } else if (this.value2 > this.value) {
        this.handle_zoom(-1);
      }
      this.value2 = e
    },
    stopChange() {
      this.value = 25;
      let buffer = MMC_Gimbal_Z40.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    handle_change_ptz_mode(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_image_mode(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_image_mode(value);
      this.commit_directive(buffer);
    },
    handle_change_thermal_mode(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_thermal_mode(value);
      this.commit_directive(buffer);
    },
    handle_change_thermal_zoom(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_thermal_zoom(value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      let buffer = MMC_Gimbal_ZT30N.zoom(this.zoom + value);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit("directive", buffer);
    },
    init_rocker() {
      this.joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      this.joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "right":
                this.buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(
                  -1,
                  this.control_speed
                );
                break;
              case "left":
                this.buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "down":
                this.buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      this.buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  watch:{
    whachChange(){
      if(this.joystick){
        this.joystick.destroy()
      }
      this.init_rocker()
    }
  },
  mounted() {
    this.$emit("width", '416px')
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>